<template>
    <div class="flex flex-col gap-2 m-3">
        <div class="flex gap-2">
            <button class="px-2 bg-gray-400" @click="active = 'a'">A</button>
            <button class="px-2 bg-gray-400" @click="active = 'b'">B</button>
        </div>
        <component :is="components[active]" />
    </div>
</template>

<script setup>
    import { ref, defineAsyncComponent } from 'vue';
    import Loading from './Test/Loading.vue'
    const active = ref('a');
    const components = {
        a: defineAsyncComponent(() => import('./Test/A.vue')),
        b: defineAsyncComponent({
            loader: () => import('./Test/B.vue'),
            loadingComponent: Loading,
            delay: 200
        }),
    }
</script>

